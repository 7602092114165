<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">NEWSLETTERS</h1>
      <router-link
        to="/addnewsletter"
        class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
        ><i class="fas fa-plus fa-sm text-white-50"></i>Add
        Newsletter</router-link
      >
    </div>
    <div class="bg-white border rounded">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Newsletter Name</th>
            <th scope="col">Newsletter Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="newsletter in newsletters" :key="newsletter._id">
            <td>{{ newsletter.name }}</td>
            <td>{{ newsletter.dateFormatted }}</td>
            <td>
              <router-link
                :to="`/editnewsletter/${newsletter._id}`"
                class="btn btn-warning mr-2"
                >Edit</router-link
              ><button
                @click.prevent="deleteNewsletter(newsletter._id)"
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "Newsletters",
  data() {
    return {
      newsletters: [],
    };
  },
  methods: {
    async getNewsletters() {
      try {
        const res = await axios.get("/api/v1/newsletter");
        console.log(res);
        if (res.status === 200) {
          this.newsletters = res.data.newsletters;
        }
      } catch (e) {
        //   ---------------- HANDLE ERROR
        console.log(e);
      }
    },
    async deleteNewsletter(id) {
      try {
        if (confirm("Are you sure?")) {
          const res = await axios.delete(`/api/v1/newsletter/${id}`, {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          });
          this.getNewsletters();
        }
      } catch (e) {
        // ------ HANDLE ERROR
        console.log(e);
      }
    },
  },
  mounted() {
    this.getNewsletters();
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>